.ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid #ccc;
}

.ql-container.ql-snow {
  border: 0;
}

.ql-editor {
  height: 500px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
