* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}

html {
  height: 100%;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.nav {
  padding: 10px;
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.footerCenter {
  display: flex;
  padding: 10px 0 10px;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.phone {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}

.phone-number {
  margin-left: 10px;
  width: 100%;
}

.input-phone-code {
  text-align: center;
}

.footerCenter .nflLogoF {
  height: 70px;
  margin-left: 0;
}

.footerCenter h3 {
  margin: 0 30px 0 30px;
  vertical-align: middle;
  color: red;
}

.footerCenter .visaLogoF {
  width: 130px;
  /* height: 40px; */
}

.lang {
  margin: 150px auto;
}

.lang h2 {
  text-align: center;
  margin-bottom: 80px;
  color: black;
}

.nflLogo {
  width: 150px;
}

.visaLogo {
  width: 60px;
}

.dufryLogo {
  width: 130px;
}

.languages {
  display: flex;
  justify-content: center;
}

.languages div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.languages div p {
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.ant-dropdown-menu {
  max-height: 250px;
  width: 180px;
  overflow: auto;
  margin: 15px 20px 15px 0;
}

.ant-dropdown-menu-title {
  width: 100%;
  margin-left: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ant-dropdown-menu-selected {
  margin-left: 20px;
}

.ant-dropdown-menu span {
  text-align: center;
}

.flags {
  width: 110px;
  margin: 30px;
  -webkit-box-shadow: 5px 5px 14px 0px rgba(0, 0, 0, 0.75);
}

.tyc {
  margin: 80px 100px 80px 100px;
}

.tyc h2 {
  text-align: center;
}

.tyc h3 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 32px;
}

.bg-main {
  /* Set rules to fill background */
  min-height: 100vh;
  /*min-width: 1024px;*/
  background: url('../images/idioma-nfl.jpeg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: absolute;
  top: 0;
  left: 0;
}

.instrucciones {
  /* Set rules to fill background */
  /* min-height: 100%; */
  /* min-width: 100vh; */
  /* min-width: 1024px; */

  /* Set up proportionate scaling */
  /*width: 100%;
    height: auto;
    position: fixed;*/

  /* Set up positioning */
  /*top: 0;
    left: 0;
    */

  background: url('../images/instrucciones.jpeg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxInstruc {
  /*margin: 10vh auto;
    margin-bottom: 0;
    height: calc(90vh - 80px);*/
  height: calc(90vh - 0px);
  text-align: center;
  color: black;
  margin-left: 80px;
  margin-right: 80px;
}

.boxInstruc h1 {
  margin-top: 70px;
  color: black;
  font-size: 48px;
}

.boxInstruc h2 {
  color: black;
  font-size: 30px;
  margin: 0 auto;
}

.boxInstruc h3 {
  color: black;
  font-size: 16px;
  margin: 30px auto;
}

.boxInstruc p {
  margin: 0 30px;
  font-size: 16px;
}

.botonJugar {
  margin: 30px;
}

.buttonPlay {
  margin: 50px;
  padding: 10px 15px;
  border: 1px solid red;
  background: white;
  border-radius: 5px;
}

.formHero {
  background: url('../images/registro.jpg') no-repeat center;
  background-size: 100% 100%;
  height: 400px;
}

.formStyle {
  width: 800px;
  margin: 40px auto;
}

.formStyle .input {
  margin: 5px 0 5px 0;
}

.formStyle .center {
  display: block;
  text-align: center;
}

.formStyle .button-form {
  display: block;
  width: 100%;
  border: none;
  margin: 15px auto 30px auto !important;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.exampleTicket {
  text-align: right;
}

.legal {
  text-align: center;
}

.legalQ {
  margin: 40px;
  text-align: center;
}

.preg {
  display: grid;
  grid-template-columns: 40% 60%;
  /* Fallback */
  height: 100vh;
  /* 100 viewport height - .nav - .legalQ  */
  height: calc(100vh - 82px - 102px);
}

.preg .pregCol-1 {
  /*background-image: linear-gradient (to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('../images/preguntas-img-aside.jpg');*/
  background: url('../images/preguntas-img-aside.jpg') fixed left;
  position: relative;
}

.preg .pregCol-2 {
  padding: 30px;
  overflow-y: auto;
}

.preg .pregCol-2 .pregCol-2-1 {
  margin: 30px 30px 50px 30px;
}

.preg .pregCol-2 .pregCol-2-1 h3 {
  color: black;
}

.ant-radio-wrapper {
  width: 100%;
}

.ant-btn.ant-btn-primary {
  background-color: #e00016;
  border: 1px solid #e00016;
  margin: 30px auto;
  padding: 0px 50px 0px 50px;
  display: flex;
  justify-content: center;
}

.thanks {
  margin: 40px;
  padding: 40px;
  font-size: 21px;
  text-align: center;
}

.thanks .thanksTitle {
  font-size: 32px;
}

.thanks .thanksResults strong {
  color: #e00016;
}

.phone-code {
  width: 20%;
}

.thanks button {
  background-color: #e00016;
  color: white;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
}

.thanksResultsBotton a {
  color: white;
}

@media screen and (max-width: 1280px) and (min-width: 601px) {

  /* Specific to this particular image */
  img.bg-main {
    left: 50%;
    margin-left: -512px;
    /* 50% */
  }

  .nav {
    padding: 10px;
    height: 80px;
    background-color: white;
    display: flex;
    justify-content: space-around;
  }

  .boxInstruc {
    /*margin: 10vh auto;
        margin-bottom: 0;
        height: calc(90vh - 80px);*/
    height: calc(90vh - 0px);
    text-align: center;
    color: black;
    margin-left: 80px;
    margin-right: 80px;
  }

  .boxInstruc h1 {
    margin-top: 70px;
    color: black;
    font-size: 48px;
  }

  .boxInstruc h2 {
    color: black;
    font-size: 30px;
  }

  .boxInstruc h3 {
    color: black;
    font-size: 16px;
    margin: 30px 0 30px 0;
  }

  .boxInstruc p {
    margin: 0 30px;
    font-size: 16px;
  }

  .botonJugar {
    margin: 30px;
  }

  .buttonPlay {
    margin: 50px;
    padding: 10px 15px;
    border: 1px solid red;
    background: white;
    border-radius: 5px;
  }

  .formHero {
    background: url('../images/registro.jpeg') no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    height: 250px;
  }

  .formStyle {
    width: 90%;
    margin: 20px auto;
  }

  .preg {
    display: grid;
    grid-template-columns: 40% 60%;
  }

  .preg .pregCol-1 {
    /*background-image: linear-gradient (to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url('../images/preguntas-img-aside.jpg');*/
    background: url('../images/preguntas-img-aside.jpg') fixed left;

    position: relative;
  }

  .preg .pregCol-2 {
    padding: 30px;
  }

  .preg .pregCol-2 .pregCol-2-1 {
    margin: 30px 30px 50px 30px;
  }

  .preg .pregCol-2 .pregCol-2-1 h3 {
    color: black;
  }

  .legal {
    margin: 25px;
    padding-bottom: 100px;
    text-align: center;
  }

  .legalQ {
    margin: 25px;
    padding-bottom: 30px;
    text-align: center;
  }

  .ant-btn.ant-btn-primary {
    background-color: #e00016;
    border: 1px solid #e00016;
    margin: 10px auto;
    padding: 0px 30px 0px 30px;
    display: flex;
  }

  .thanks {
    margin: 40px;
    padding: 40px;
    font-size: 21px;
    text-align: center;
  }

  .thanks .thanksTitle {
    font-size: 32px;
  }

  .thanks .thanksResults strong {
    color: #e00016;
  }

  .thanks button {
    background-color: #e00016;
    color: white;
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
  }

  .thanksResultsBotton a {
    color: white;
  }
}

@media screen and (max-width: 600px) {
  * {
    margin: auto;
  }

  .phone-code {
    width: 50%;
  }

  .nav {
    background-color: white;
    display: flex;
    justify-content: space-around;
  }

  .lang {
    margin: 100px auto;
  }

  .lang h2 {
    text-align: center;
    margin-bottom: 80px;
    font-size: 18px;
    color: black;
  }

  .tyc {
    margin: 30px;
  }

  .tyc h2 {
    text-align: center;
  }

  .tyc h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 32px;
  }

  .instrucciones {
    color: black;
  }

  .boxInstruc {
    text-align: center;
    display: inline-block;
    color: black;
  }

  .boxInstruc h1 {
    color: black;
    font-size: 42px;
  }

  .boxInstruc h2 {
    color: black;
    font-size: 22px;
    margin: 0px 0 30px 0;
  }

  .boxInstruc h3 {
    color: black;
    font-size: 16px;
    margin: 30px 0 30px 0;
  }

  .boxInstruc p {
    margin: 0px;
    font-size: 16px;
  }

  .botonJugar {
    margin: 10px 0 30px 0;
  }

  .formHero {
    background: url('../images/registro.jpeg') no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    height: 200px;
  }

  .formStyle {
    width: 90%;
    margin: 20px auto;
  }

  .formStyle p {
    margin: 5px 0 5px 0;
  }

  .footerCenter .nflLogoF {
    height: 70px;
    padding: 10px;
  }

  .legal {
    margin: 25px;
    padding-bottom: 100px;
    text-align: center;
  }

  .legalQ {
    margin: 25px;
    padding-bottom: 30px;
    text-align: center;
  }

  .preg {
    display: grid;
    grid-template-columns: 100%;
    height: auto;
  }

  .preg .pregCol-1 {
    display: none;
  }

  .preg .pregCol-2 {
    padding: 20px;
  }

  .preg .pregCol-2 .pregCol-2-1 {
    margin: 30px 10px 30px 10px;
  }

  .preg .pregCol-2 .pregCol-2-1 h3 {
    color: black;
  }

  .ant-radio-wrapper {
    width: 80%;
    white-space: normal;
  }

  .ant-btn.ant-btn-primary {
    background-color: #e00016;
    border: 1px solid #e00016;
    margin-top: 30px;
    padding: 0px 30px 0px 30px;
    display: flex;
  }

  .footerMain .footerCenter {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .thanks {
    margin: 20px;
    padding: 20px;
    font-size: 21px;
    text-align: center;
  }

  .thanks .thanksTitle {
    font-size: 32px;
    margin: 0px;
  }

  .thanks .thanksTitle p {
    margin: 0px;
  }

  .thanks .thanksResults strong {
    color: #e00016;
  }

  .thanks button {
    background-color: #e00016;
    color: white;
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
    margin-bottom: 40px;
  }

  .thanksResultsBotton a {
    color: white;
  }
}

.dufry-form-button {
  background-color: #e00016;
  color: white;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 40px;
  width: 100%;
  cursor: pointer;
}

.sections-editor h1.title {
  text-align: center;
  font-size: 48px;
}

.footerNotice {
  margin-top: 45px;
}

.footerNotice p {
  margin-top: 0;
  margin-bottom: 0;
  color: #4f4f4f;
  font-size: 0.8rem;
}